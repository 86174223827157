<template>
   <div class="grid grid-cols-2">
      <div class="bg-white rounded-lg border border-gray-200 p-10 text-center hover:bg-gray-50 hover:border-gray-300 cursor-pointer">
         <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto my-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
         </svg>
         <p class="font-medium text-gray-500">Add Bank</p>
      </div>
   </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
   name: "Banking",
   
   components: {
      
   },

   data() {
      return {

      }
   },

   methods: {

   },

   computed: {
      ...mapGetters('auth', ['user'])
   }
}
</script>